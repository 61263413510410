import {
  BlobServiceClient,
  BlockBlobClient,
  ContainerClient,
} from "@azure/storage-blob";
import {
  InterviewQuestionDetails,
  InterviewQuestionListItem,
} from "./InterviewQuestionModels";

export function getContainerClient(blobServiceUri?: string) {
  try {
    if (blobServiceUri) {
      return new BlobServiceClient(blobServiceUri).getContainerClient(
        "questions"
      );
    }
  } catch {}
}

export function getBlockBlobClient(blobName: string, blobServiceUri?: string) {
  const containerClient = getContainerClient(blobServiceUri);
  if (containerClient) {
    return containerClient.getBlockBlobClient(blobName);
  }
}

export async function fetchQuestions(client: ContainerClient) {
  let results: InterviewQuestionListItem[] | undefined;
  try {
    const items: InterviewQuestionListItem[] = [];
    for await (const blob of client.listBlobsFlat({ includeMetadata: true })) {
      items.push(fromMetadata(blob.name, blob.metadata || {}));
    }
    results = items;
  } catch {}

  return { results, client };
}

const fetchedMap: Record<string, InterviewQuestionDetails> = {};

export async function fetchQuestionDetails(
  name: string,
  client: BlockBlobClient
) {
  let results: InterviewQuestionDetails | undefined = fetchedMap[name];
  if (!results) {
    try {
      const { blobBody, metadata } = await client.download();
      const text = await new Response(await blobBody).text();
      results = {
        text,
        ...fromMetadata(name, metadata || {}),
      };
      fetchedMap[name] = results;
    } catch {}
  }
  return { results, client };
}

export async function saveQuestionDetails(
  client: BlockBlobClient,
  newQuestionDetails: InterviewQuestionDetails
) {
  const metadata = toMetadata(newQuestionDetails);
  const text = newQuestionDetails.text;
  await client.upload(text, text.length, { metadata });
  fetchedMap[newQuestionDetails.id] = newQuestionDetails;
}

function fromMetadata(
  name: string,
  metadata: Record<string, string>
): InterviewQuestionListItem {
  const state = metadata["state"] ? metadata["state"].toLowerCase() : "TODO";
  const questions = Object.keys(metadata)
    .filter((c) => c.toLowerCase().startsWith("question"))
    .sort()
    .map((key) => metadata[key]);
  return {
    question: questions[0],
    id: name,
    state,
    questions,
  };
}

function toMetadata({ questions, state }: InterviewQuestionDetails) {
  const metadata: Record<string, string> = {};
  metadata["state"] = state;
  for (let i = 0; i < questions.length; i++) {
    metadata["question" + i] = questions[i];
  }
  return metadata;
}
