import { DetailsList, IColumn, Shimmer } from "@fluentui/react";
import { FunctionComponent, useMemo } from "react";
import { InterviewQuestionListItem } from "./InterviewQuestionModels";

export interface QuestionsListViewProps {
  loadingQuestions: boolean;
  onOpenQuestionChange: (question: InterviewQuestionListItem) => void;
  questions: InterviewQuestionListItem[] | undefined;
}

export const QuestionsListView: FunctionComponent<QuestionsListViewProps> = ({
  loadingQuestions,
  onOpenQuestionChange,
  questions,
}) => {
  const columns = useMemo<IColumn[]>(
    () => [
      {
        key: "question",
        name: "Question",
        minWidth: 150,
        onRender: (item: InterviewQuestionListItem) => {
          return (
            <a href="#" onClick={() => onOpenQuestionChange(item)}>
              {item.question}
            </a>
          );
        },
      },
    ],
    [onOpenQuestionChange]
  );
  return (
    <Shimmer isDataLoaded={!loadingQuestions}>
      {questions ? (
        <DetailsList columns={columns} items={questions} />
      ) : (
        "Failed to get questions. Please update Blob URL in settings."
      )}
    </Shimmer>
  );
};
