import { Panel, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { FunctionComponent, useCallback, useState } from "react";

export interface SettingsPanelProps {
  blobServiceUri: string | undefined;
  isOpen: boolean;
  onBlobServiceUriChange: (newBlobServiceUri?: string) => void;
  onDismiss: () => void;
}

export const SettingsPanel: FunctionComponent<SettingsPanelProps> = ({
  isOpen,
  blobServiceUri,
  onBlobServiceUriChange,
  onDismiss,
}) => {
  const [currentUri, setCurrentUri] = useState(blobServiceUri);
  const onCurrentUriTextFieldChange = useCallback(
    (ev: any, value?: string) => {
      value && setCurrentUri(value);
    },
    [setCurrentUri]
  );
  const onSave = useCallback(() => {
    onBlobServiceUriChange(currentUri);
    onDismiss();
  }, [currentUri, onBlobServiceUriChange, onDismiss]);
  return (
    <Panel isOpen={isOpen} onDismiss={onDismiss}>
      <Stack tokens={{ childrenGap: 16 }}>
        <TextField
          label="Blob service URL"
          value={currentUri}
          onChange={onCurrentUriTextFieldChange}
        />
        <PrimaryButton onClick={onSave}>Save</PrimaryButton>
      </Stack>
    </Panel>
  );
};
