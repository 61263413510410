import { useEffect, useState } from "react";
import { fetchQuestions, getContainerClient } from "./BlobStorageClient";
import { InterviewQuestionListItem } from "./InterviewQuestionModels";

export const useInterviewQuestions = (blobServiceUri?: string) => {
  const [questions, setQuestions] = useState<InterviewQuestionListItem[]>();
  const [loadingQuestions, setLoadingQuestions] = useState(true);

  useEffect(() => {
    const containerClient = getContainerClient(blobServiceUri);
    if (containerClient) {
      setLoadingQuestions(true);
      fetchQuestions(containerClient).then(({ client, results }) => {
        if (client === containerClient) {
          setQuestions(results);
          setLoadingQuestions(false);
        }
      });
    } else {
      setQuestions(undefined);
      setLoadingQuestions(false);
    }
  }, [blobServiceUri, setLoadingQuestions, setQuestions]);

  return { questions, loadingQuestions };
};
