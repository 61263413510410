import {
  DefaultButton,
  makeStyles,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  ScrollablePane,
  Shimmer,
  Stack,
} from "@fluentui/react";
import { marked } from "marked";
import {
  FunctionComponent,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useInterviewQuestionDetails } from "./UseInterviewQuestionDetails";

const MonacoEditor = lazy(() => import("react-monaco-editor"));

const useStyles = makeStyles({
  previewContainerCss: { position: "relative", height: `calc(100vh - 224px)` },
  editContainerCss: { height: `calc(100vh - 224px)` },
});

export interface QuestionPanelProps {
  blobServiceUri: string | undefined;
  id: string;
  isOpen: boolean;
  onDismiss: () => void;
  question: string;
}

export const QuestionPanel: FunctionComponent<QuestionPanelProps> = ({
  blobServiceUri,
  id,
  isOpen,
  onDismiss,
  question,
}) => {
  const { loadingQuestionDetails, onSaveQuestionDetails, questionDetails } =
    useInterviewQuestionDetails(id, blobServiceUri);
  const [currentMarkdown, setCurrentMarkdown] = useState("");
  const [currentHtml, setCurrentHtml] = useState("");
  const { previewContainerCss, editContainerCss } = useStyles();

  const onMarkdownChange = useCallback(
    (newMarkdown: string) => {
      setCurrentMarkdown(newMarkdown);
      setCurrentHtml(marked(newMarkdown));
    },
    [setCurrentMarkdown, setCurrentHtml]
  );

  useEffect(() => {
    onMarkdownChange((questionDetails && questionDetails.text) || "");
  }, [onMarkdownChange, questionDetails]);

  const onSave = useCallback(async () => {
    if (questionDetails) {
      await onSaveQuestionDetails({
        ...questionDetails,
        text: currentMarkdown,
      });
    }
    onDismiss();
  }, [currentMarkdown, onDismiss, onSaveQuestionDetails, questionDetails]);

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={onDismiss}
      type={PanelType.medium}
    >
      {id && (
        <Stack tokens={{ childrenGap: 8 }}>
          <h2>{question}</h2>
          <Pivot>
            <PivotItem headerText="Preview">
              <ScrollablePane className={previewContainerCss}>
                <Shimmer isDataLoaded={!loadingQuestionDetails}>
                  <div
                    style={{ overflow: "hidden" }}
                    dangerouslySetInnerHTML={{ __html: currentHtml }}
                  />
                </Shimmer>
              </ScrollablePane>
            </PivotItem>
            <PivotItem headerText="Edit">
              <section className={editContainerCss}>
                <Suspense fallback={"Loading..."}>
                  <MonacoEditor
                    height="100%"
                    onChange={(newValue) => onMarkdownChange(newValue)}
                    value={currentMarkdown}
                  />
                </Suspense>
              </section>
            </PivotItem>
          </Pivot>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <PrimaryButton
              disabled={questionDetails?.text === currentMarkdown}
              onClick={onSave}
            >
              Save
            </PrimaryButton>
            <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
          </Stack>
        </Stack>
      )}
    </Panel>
  );
};
