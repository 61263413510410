import { IconButton, makeStyles } from "@fluentui/react";
import { useBoolean, useConst } from "@fluentui/react-hooks";
import { FunctionComponent, useCallback, useState } from "react";
import { InterviewQuestionListItem } from "./InterviewQuestionModels";
import { QuestionPanel } from "./QuestionPanel";
import { QuestionsListView } from "./QuestionsListView";
import { SettingsPanel } from "./SettingsPanel";
import { useInterviewQuestions } from "./UseInterviewQuestions";

const useStyles = makeStyles({
  appHeaderCss: {
    display: "flex",
    justifyContent: "space-between",
    margin: "18px 14px",
  },
});
const App: FunctionComponent = () => {
  const { appHeaderCss } = useStyles();
  const [
    isSettingsOpen,
    { setTrue: onSettingsPanelOpen, setFalse: onSettingsPanelDismiss },
  ] = useBoolean(false);
  const initialBlobServiceUri = useConst(
    () => localStorage.getItem("interviewprep_blobServiceUri") || undefined
  );
  const [blobServiceUri, setBlobServiceUri] = useState(initialBlobServiceUri);
  const onBlobServiceUriChange = useCallback(
    (newBlobServiceUri?: string) => {
      localStorage.setItem(
        "interviewprep_blobServiceUri",
        newBlobServiceUri || ""
      );
      setBlobServiceUri(newBlobServiceUri);
    },
    [setBlobServiceUri]
  );

  const { questions, loadingQuestions } = useInterviewQuestions(blobServiceUri);
  const [
    isQuestionPanelOpen,
    { setTrue: onQuestionPanelOpen, setFalse: onQuestionPanelDismiss },
  ] = useBoolean(false);
  const [openQuestion, setOpenQuestion] = useState<InterviewQuestionListItem>();
  const onOpenQuestionChange = useCallback(
    (newOpenQuestion) => {
      setOpenQuestion(newOpenQuestion);
      onQuestionPanelOpen();
    },
    [onQuestionPanelOpen]
  );

  return (
    <>
      <section className={appHeaderCss}>
        <header>Interview Preparation</header>
        <IconButton
          iconProps={{ iconName: "Settings" }}
          onClick={onSettingsPanelOpen}
        />
      </section>
      <QuestionsListView
        loadingQuestions={loadingQuestions}
        onOpenQuestionChange={onOpenQuestionChange}
        questions={questions}
      />
      <SettingsPanel
        blobServiceUri={blobServiceUri}
        isOpen={isSettingsOpen}
        onBlobServiceUriChange={onBlobServiceUriChange}
        onDismiss={onSettingsPanelDismiss}
      />
      {openQuestion && (
        <QuestionPanel
          blobServiceUri={blobServiceUri}
          id={openQuestion.id}
          isOpen={isQuestionPanelOpen}
          onDismiss={onQuestionPanelDismiss}
          question={openQuestion.question}
        />
      )}
    </>
  );
};

export default App;
