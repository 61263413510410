import { useCallback, useEffect, useState } from "react";
import {
  fetchQuestionDetails,
  getBlockBlobClient,
  saveQuestionDetails,
} from "./BlobStorageClient";
import { InterviewQuestionDetails } from "./InterviewQuestionModels";

export const useInterviewQuestionDetails = (
  id: string,
  blobServiceUri?: string
) => {
  const [loadingQuestionDetails, setLoadingQuestionDetails] = useState(true);
  const [questionDetails, setQuestionDetails] =
    useState<InterviewQuestionDetails>();

  useEffect(() => {
    const blockBlobClient = getBlockBlobClient(id, blobServiceUri);
    if (blockBlobClient) {
      setLoadingQuestionDetails(true);
      fetchQuestionDetails(id, blockBlobClient).then(({ client, results }) => {
        if (client === blockBlobClient) {
          setQuestionDetails(results);
          setLoadingQuestionDetails(false);
        }
      });
    } else {
      setQuestionDetails(undefined);
      setLoadingQuestionDetails(false);
    }
  }, [id, blobServiceUri, setLoadingQuestionDetails, setQuestionDetails]);

  const onSaveQuestionDetails = useCallback(
    async (newQuestionDetails: InterviewQuestionDetails) => {
      const blockBlobClient = getBlockBlobClient(id, blobServiceUri);
      if (blockBlobClient) {
        await saveQuestionDetails(blockBlobClient, newQuestionDetails);
        setQuestionDetails(newQuestionDetails);
      }
    },
    [id, blobServiceUri, setQuestionDetails]
  );

  return {
    loadingQuestionDetails,
    onSaveQuestionDetails,
    questionDetails,
  };
};
